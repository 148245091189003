import { mapMutations } from 'vuex'
export default {
  components: {
    User: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/User'),
    G2Academy: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/G2Academy')
  },
  methods: {
    ...mapMutations('common', ['toggleSideBar']),
    toggle() {
      this.toggleSideBar()
    }
  }
}
